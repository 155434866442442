import cx from "classnames";
import React from "react";

export default function Mute(props) {
  const { muted, onClick } = props;

  const iconClass = cx("fa", {
    "fa-microphone-slash": muted,
    "fa-microphone": !muted,
  });

  return (
    <div className="VideoContainer-muteIcon" onClick={onClick}>
      <i className={iconClass} />
    </div>
  );
}
