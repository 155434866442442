import _ from "lodash";
import { CarouselProvider, DotGroup } from "pure-react-carousel";
import React from "react";

import StyledSlider from "./styled-slider";

import "pure-react-carousel/dist/react-carousel.es.css";

const regex = /\#item-(\d+)/;

export default function Full(props) {
  const { gridItems } = props;

  const matches = window.location.hash && window.location.hash.match(regex);
  const selectedGridItem = matches ? _.find(gridItems, { authorized: true, id: matches[1] }) : null;
  const initialSelectedItem = selectedGridItem
    ? _.first(selectedGridItem.gridItemMediaObjects).id
    : null;
  const [selectedItem, setSelectedItem] = React.useState(null);

  function handleSetSelectedItem(id) {
    setSelectedItem(id);
  }

  function handleUnsetSelectedItem(id) {
    setSelectedItem(null);
  }

  return (
    <div className="ApexFull">
      {_.map(gridItems, (gridItem) => {
        const firstObj = _.first(gridItem.gridItemMediaObjects);

        let naturalSlideWidth = firstObj.width;
        let naturalSlideHeight = firstObj.height;

        if (firstObj.width / firstObj.height < 3 / 4) {
          naturalSlideWidth = 3;
          naturalSlideHeight = 4;
        } else if (firstObj.width / firstObj.height > 16 / 9) {
          naturalSlideWidth = 16;
          naturalSlideHeight = 9;
        }

        return (
          <div className="ApexFull-item" id={`item-${gridItem.id}`} key={gridItem.id}>
            <div className="ApexFull-content">
              <CarouselProvider
                naturalSlideWidth={naturalSlideWidth}
                naturalSlideHeight={naturalSlideHeight}
                touchEnabled={false}
                totalSlides={gridItem.gridItemMediaObjects.length}
              >
                <StyledSlider
                  gridItem={gridItem}
                  naturalSlideHeight={naturalSlideHeight}
                  naturalSlideWidth={naturalSlideWidth}
                  onSetSelectedItem={handleSetSelectedItem}
                  onUnsetSelectedItem={handleUnsetSelectedItem}
                  selectedItem={selectedItem}
                />
                {gridItem.gridItemMediaObjects.length > 1 && <DotGroup />}
              </CarouselProvider>

              {gridItem.caption && (
                <div
                  className="ApexFull-caption Markdown"
                  dangerouslySetInnerHTML={{
                    __html: gridItem.formattedCaption,
                  }}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
