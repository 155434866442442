import React from "react";

import { EnvContext } from "@hooks/use-env";
import Full from "./full";
import Grid from "./grid";
import Header from "./header";

export default function ApexMembers(props) {
  const { env, gridItems, headerImageSrc, variant } = props;

  return (
    <EnvContext.Provider value={env}>
      {variant === "grid" && (
        <React.Fragment>
          <Header headerImageSrc={headerImageSrc} />
          <Grid gridItems={gridItems} />
        </React.Fragment>
      )}

      {variant === "full" && <Full gridItems={gridItems} />}
    </EnvContext.Provider>
  );
}
