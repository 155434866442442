import _ from "lodash";
import React from "react";

import GridItem from "@components/grid-item";

export default function Grid(props) {
  const { gridItems } = props;
  return (
    <div className="ApexGrid">
      {_.map(gridItems, (gridItem) => (
        <GridItem gridItem={gridItem} key={gridItem.id} />
      ))}
    </div>
  );
}
