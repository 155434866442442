import cx from "classnames";
import React from "react";

import useOnClickOutside from "@hooks/use-on-click-outside";

export default function Header(props) {
  const { headerImageSrc } = props;

  const ref = React.useRef(null);
  const [showPopup, setShowPopup] = React.useState(false);

  function handleClose() {
    setShowPopup(false);
  }

  function handleToggle() {
    setShowPopup((value) => !value);
  }

  useOnClickOutside(ref, handleClose);

  const visibilityClass = showPopup ? "visible" : "invisible";
  const bodyClassName = cx("ApexHeader-explainBody", visibilityClass);

  return (
    <header className="ApexHeader">
      <img src={headerImageSrc} className="ApexHeader-image" />

      <div className="ApexHeader-explain" ref={ref}>
        <div className="ApexHeader-explainLink" onClick={handleToggle}>
          ?
        </div>

        <div className={bodyClassName}>
          <div className="ApexHeader-explainBodyBg"></div>
          <div className="ApexHeader-explainBodyUnderlay"></div>

          <p>
            <strong>venus apex</strong> is a rolling feed of references, visual inspiration and
            pornography that exists outside of a film format.
          </p>

          <p>
            intimate, personal and made for continuous discovery and exploration, the feed is always
            changing and in flux with new posts and connections being added all the time.
            <br />
            &mdash; all handmade and curated by <a href="/vex">Vex</a>.
          </p>

          <p>
            <a href="/">
              <strong>a four chambered heart</strong>
            </a>{" "}
            archive
          </p>
        </div>
      </div>
    </header>
  );
}
