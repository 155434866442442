import Hls from "hls.js";
import mux from "mux-embed";
import React from "react";

import { useEnv } from "@hooks/use-env";
import { transformPayload } from "@utils/jsonapi-utils";

import Mute from "./mute";

export default function VideoContainer(props) {
  const { authorized = false, gridItemMediaObject, onPause, onPlay, play } = props;

  const env = useEnv();
  const [showPlayButton, setShowPlayButton] = React.useState(false);
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [isMuted, setIsMuted] = React.useState(null);
  const videoRef = React.useRef(null);
  const [videoIsLoading, setVideoIsLoading] = React.useState(false);

  const style = {
    backgroundImage: `url("${gridItemMediaObject.muxThumbnailFull}")`,
  };

  function handlePause() {
    setShowPlayButton(false);
  }

  function handlePlaying() {
    setVideoIsLoading(false);
    setShowPlayButton(true);
  }

  function handleLoadStart() {
    setVideoIsLoading(true);
  }

  function handleClickOverlay() {
    const video = videoRef.current;

    if (!videoIsLoading && video && authorized) {
      if (!video.paused) {
        onPause(gridItemMediaObject.id);
      } else {
        onPlay(gridItemMediaObject.id);
        video.muted = false;
      }
    }
  }

  async function handleAttach() {
    if (videoRef.current) {
      const video = videoRef.current;
      const response = await fetch(gridItemMediaObject.signatureUrl);

      if (response.status === 200) {
        const json = await response.json();
        const model = transformPayload(json);
        const url = model.videoUrl;

        let hls;

        if (video.canPlayType("application/vnd.apple.mpegurl")) {
          video.src = url;
        } else if (Hls.isSupported()) {
          hls = new Hls();
          hls.loadSource(url);
          hls.attachMedia(video);
        }

        if (hls) {
          mux.monitor(videoRef.current, {
            debug: env.muxDataDebug,
            hlsjs: hls,
            Hls: Hls,

            data: {
              env_key: env.muxDataEnv,
              player_name: "Apex",
              player_init_time: Date.now(),
            },
          });
        }

        setHasLoaded(true);
      }
    }
  }

  function handleVolumeChange(event) {
    setIsMuted(event.target.muted);
  }

  React.useEffect(() => {
    const video = videoRef.current;

    if (video && video.paused && play === true) {
      if (!hasLoaded) {
        handleAttach();
        video.play();
      } else {
        video.play();
      }
    }

    if (video && !video.paused && play === false) {
      video.pause();
    }
  }, [videoRef.current, videoRef.current?.paused, play]);

  function handleToggleMute() {
    if (videoRef.current) {
      const video = videoRef.current;
      video.muted = !video.muted;
    }
  }

  return (
    <div className="VideoContainer">
      <div className="VideoContainer-thumbnail" style={style}></div>

      <video
        className="VideoContainer-video"
        id={`video-${gridItemMediaObject.id}`}
        loop
        muted
        onLoadStart={handleLoadStart}
        onPlaying={handlePlaying}
        onPause={handlePause}
        onVolumeChange={handleVolumeChange}
        playsInline
        ref={videoRef}
      />

      {videoIsLoading && <div className="VideoContainer-loadingOverlay">LOADING</div>}
      <div className="VideoContainer-playOverlay" onClick={handleClickOverlay} />
      {showPlayButton && <Mute muted={isMuted} onClick={handleToggleMute} />}

      {!showPlayButton && !videoIsLoading && <div className="VideoContainer-playButton" />}
    </div>
  );
}
