import { ButtonBack, ButtonNext, CarouselContext, Slide, Slider } from "pure-react-carousel";
import React from "react";

import Lock from "@components/lock";
import VideoContainer from "@components/video-container";

export default function StyledSlider(props) {
  const {
    gridItem,
    naturalSlideHeight,
    naturalSlideWidth,
    onSetSelectedItem,
    onUnsetSelectedItem,
    selectedItem,
  } = props;

  const carouselContext = React.useContext(CarouselContext);

  const [currentSlide, setCurrentSlide] = React.useState(carouselContext.state.currentSlide);

  React.useEffect(() => {
    function onChange() {
      if (currentSlide !== carouselContext.state.currentSlide) {
        const gridItemMediaObject =
          gridItem.gridItemMediaObjects[carouselContext.state.currentSlide];
        onSetSelectedItem(gridItemMediaObject.id);
        setCurrentSlide(carouselContext.state.currentSlide);
      }
    }

    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext.state.currentSlide, onSetSelectedItem, currentSlide]);

  const showArrows = gridItem.gridItemMediaObjects.length > 1;

  return (
    <React.Fragment>
      <div className="ApexFull-slider">
        {!gridItem.authorized && <Lock />}
        <Slider>
          {_.map(gridItem.gridItemMediaObjects, (gridItemMediaObject, index) => {
            const shouldDisplay = index === 0 || (gridItem.authorized && index > 0);

            return (
              <Slide index={index} key={gridItemMediaObject.id}>
                {shouldDisplay && (
                  <React.Fragment>
                    {gridItemMediaObject.isVideo && (
                      <VideoContainer
                        authorized={gridItem.authorized}
                        gridItemMediaObject={gridItemMediaObject}
                        naturalSlideHeight={naturalSlideHeight}
                        naturalSlideWidth={naturalSlideWidth}
                        onPause={onUnsetSelectedItem}
                        onPlay={onSetSelectedItem}
                        play={selectedItem === gridItemMediaObject.id}
                      />
                    )}

                    {gridItemMediaObject.isImage && (
                      <img
                        className="ApexFull-image"
                        src={gridItemMediaObject.imgixThumbnailFull}
                      />
                    )}
                  </React.Fragment>
                )}
              </Slide>
            );
          })}
        </Slider>

        {gridItem.authorized && showArrows && (
          <React.Fragment>
            <ButtonBack className="ApexFull-arrow back">
              <i className="fa fa-arrow-circle-left" />
            </ButtonBack>
            <ButtonNext className="ApexFull-arrow next">
              <i className="fa fa-arrow-circle-right" />
            </ButtonNext>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
