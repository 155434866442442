import _ from "lodash";

function formatKey(_value, key, _object) {
  return _.camelCase(key);
}

function handleTransform(json, included) {
  const transformedObject = _.mapKeys(json.attributes, formatKey);
  transformedObject.id = json.id;

  _.each(json.relationships, (value, key) => {
    transformedObject[_.camelCase(key)] = _.flatMap(value.data, (obj) => {
      const filteredIncluded = _.filter(included, {
        id: obj.id,
        type: obj.type,
      });

      const mapped = _.map(filteredIncluded, (obj) => _.assign(obj.attributes, { id: obj.id }));
      const transformed = _.map(mapped, (obj) => _.mapKeys(obj, formatKey));
      return transformed;
    });
  });

  return transformedObject;
}

export function transformPayload(response) {
  if (_.isArray(response.data)) {
    return _.map(response.data, (attributes) => handleTransform(attributes, response.included));
  }

  return handleTransform(response.data, response.included);
}

export function transformErrors(errors) {
  const transformedObject = _.mapKeys(errors, formatKey);
  return transformedObject;
}
