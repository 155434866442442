import React from "react";

import useHover from "@hooks/use-hover";
import { transformPayload } from "@utils/jsonapi-utils";

export default function VideoThumbnail(props) {
  const { gridItemMediaObject } = props;

  const [hoverRef, isHovered] = useHover();
  const [gifUrl, setGifUrl] = React.useState(null);
  const [isLoadingGif, setIsLoadingGif] = React.useState(false);

  React.useEffect(() => {
    if (!isLoadingGif && isHovered && !gifUrl) {
      handleGetGifUrl();
    }
  }, [gifUrl, handleGetGifUrl, isLoadingGif, isHovered]);

  async function handleGetGifUrl() {
    setIsLoadingGif(true);
    const response = await fetch(gridItemMediaObject.signatureUrl);

    if (response.status === 200) {
      const json = await response.json();
      const model = transformPayload(json);

      setGifUrl(model.gifUrl);
      setIsLoadingGif(false);
    }
  }

  return (
    <div className="ApexGrid-videoThumbnail" ref={hoverRef}>
      <div
        className="ApexGrid-videoThumbnailSrc"
        style={{
          backgroundImage: `url(${gridItemMediaObject.muxThumbnailGrid})`,
        }}
      />

      {isHovered && gifUrl && (
        <div
          className="ApexGrid-videoThumbnailSrc animated"
          style={{
            backgroundImage: `url(${gifUrl})`,
          }}
        />
      )}

      <div className="ApexGrid-videoThumbnailPlay" />
    </div>
  );
}
