import _ from "lodash";
import React from "react";

import Lock from "@components/lock";

import VideoThumbnail from "./video-thumbnail";

export default function GridItem(props) {
  const { gridItem } = props;

  const sortedObjects = _.orderBy(gridItem.gridItemMediaObjects, ["position", "asc"]);
  const firstObj = _.first(sortedObjects);
  const anchor = `#item-${gridItem.id}`;
  const url = `${gridItem.publicUrl}${anchor}`;

  return (
    <div className="ApexGrid-item">
      <a className="ApexGrid-link" href={url}>
        <div className="ApexGrid-content">
          {gridItem.mediaType === "multiple" && (
            <div className="ApexGrid-itemIcon">
              <i className={`fa fa-${gridItem.icon}`} />
            </div>
          )}

          {firstObj.isVideo && <VideoThumbnail gridItemMediaObject={firstObj} />}

          {firstObj.isImage && (
            <div
              className="ApexGrid-imageThumbnail"
              style={{ backgroundImage: `url("${gridItem.imgixThumbnailGrid}")` }}
            />
          )}
        </div>
      </a>
      {!gridItem.authorized && <Lock />}
    </div>
  );
}
