import React from "react";
import ReactDOM from "react-dom";

import ApexMembers from "@components/apex-members";
import { transformPayload } from "@utils/jsonapi-utils";

const container = document.querySelector("#apex");

if (container) {
  const { gridItemsJson, headerImageSrc, variant } = window.apexData;
  const { env } = window;

  const gridItems = transformPayload(gridItemsJson);

  ReactDOM.render(
    <ApexMembers
      env={env}
      gridItems={gridItems}
      headerImageSrc={headerImageSrc}
      variant={variant}
    />,
    container
  );
}
